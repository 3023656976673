import * as moduleAuth from './auth.js'
import * as moduleTime from './time.js'

export default (() => {
	const modules = {
		auth: moduleAuth,
		time: moduleTime,
	}
	for (const moduleName of Object.keys(modules)) {
		modules[moduleName] = {namespaced: true, ...modules[moduleName]}
	}

	return modules
})()
