export const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	// TODO: make this differentiate between production and development?!
	url: (location.hostname === 'localhost') ? 'http://localhost:8080/auth' : `https://${location.hostname}/auth`,
	// This must be true.
	handleCodeInApp: true,
	// IOS: {
	//   bundleId: 'com.example.ios',
	// },
	// android: {
	//   packageName: 'com.example.android',
	//   installApp: true,
	//   minimumVersion: '12',
	// },
	// dynamicLinkDomain: 'localhost',
}

export default actionCodeSettings
