import {createRouter, createWebHistory} from '@ionic/vue-router'
import {store} from '@/store/index.js'

const routes = [
	{
		name: 'auth',
		path: '/auth',
		component: () => import('@/views/Authenticate.vue'),
	},
	{
		name: 'logout',
		path: '/logout',
		component: () => import('@/views/Authenticate.vue'),
	},
	{
		name: 'notificationSend',
		path: '/notification/send',
		component: () => import('@/views/NotificationSend.vue'),
	},
	{
		name: 'notificationList',
		path: '/notifications',
		component: () => import('@/views/NotificationList.vue'),
	},
	{
		path: '/notification',
		redirect: {name: 'notificationList'},
	},
	{
		name: 'articleList',
		path: '/articles',
		component: () => import('@/views/ArticleList.vue'),
	},
	{
		path: '/article',
		redirect: {name: 'articleList'},
	},
	{
		name: 'articleNew',
		path: '/articles/new',
		component: () => import('@/views/ArticleNew.vue'),
	},
	{
		name: 'articleSingle',
		path: '/article/:id',
		component: () => import('@/views/ArticleSingle.vue'),
	},
	// {
	//   name: 'settings',
	//   path: '/settings',
	//   component: () => import('@/views/Settings.vue'),
	// },
	{
		path: '',
		redirect: {name: 'articleList'},
	},
	// {
	//   path: '',
	//   redirect: '/folder/Inbox',
	// },
	// {
	//   path: '/folder/:id',
	//   component: () => import('@/views/FolderPage.vue'),
	// },
]

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes,
})

const waitForStorageToBeReady = async (to, from, next) => {
	await store.restored // Needs to be called to make sure the store is actually restored!
	return next()
}

const waitForAuthCheck = async (to, from, next) => {
	await store.dispatch('auth/restore')
	if (to.name !== 'auth' && (!store.state.auth.userCurrent || !store.state.auth.isAdmin)) {
		console.log('no user logged in')
		return next({name: 'auth'})
	}

	return next()
}

router.beforeEach(waitForStorageToBeReady)
router.beforeEach(waitForAuthCheck)

export default router
