import VuexPersistence from 'vuex-persist'
import createCache from 'vuex-cache'
import {createStore} from 'vuex'
import localForage from 'localforage'

import modules from './modules/index.js'

export const vuexPersist = new VuexPersistence({
	// StrictMode: true, // This **MUST** be set to true
	// storage: window.localStorage,
	storage: localForage,
	// Modules: ['time'], //only save user module
	// reducer: (state) => ({
	//   // articles: state['articles'],
	//   // youtubePlaylists: state['youtubePlaylists'],
	//   // fitogramEvents: state['fitogramEvents'],
	// }),
	filter(mutation) {
		// Console.log('mutation', mutation)
		if (mutation.type.startsWith('time/')) {
			return false
		}

		// If (mutation.type.startsWith('device/')) return false
		// if (mutation.type.startsWith('auth/')) return false
		// if (mutation.type.startsWith('youtubePlaylists/')) return true
		return false // Change to true if you want to use storage
	},
	asyncStorage: true,
	// SupportCircular: true,
})

export const store = createStore({
	modules,
	// State: {},
	// mutations: {},
	// actions: {},
	// getters: {},
	mutations: {
		// Only in strict mode, but strict mode persist is broken?
		RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION, // This mutation **MUST** be named "RESTORE_MUTATION"
	},
	strict: false, // TODO: make this not true in production
	plugins: [createCache({timeout: 60 * 60}), vuexPersist.plugin],
})

export default store
