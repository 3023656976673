// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
// Import { getAnalytics } from 'firebase/analytics'
import {initializeFirestore, CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, connectFirestoreEmulator} from 'firebase/firestore'
import {initializeAuth, connectAuthEmulator, browserLocalPersistence} from 'firebase/auth'
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'
// Import { getStorage, connectStorageEmulator } from 'firebase/storage'
// import { getPerformance } from 'firebase/performance'

// import store from '@/store'

import firebaseConfigObject from '../.firebase-config.json'

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = firebaseConfigObject.result.sdkConfig

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const firestoreDb = initializeFirestore(firebaseApp, {
	cacheSizeBytes: CACHE_SIZE_UNLIMITED,
})
export const firebaseAuth = initializeAuth(firebaseApp, {
	persistence: browserLocalPersistence,
})
export const firebaseFunctions = getFunctions(firebaseApp)
// Export const firebaseStorage = getStorage(firebaseApp)
// export const firebasePerformance = getPerformance(firebaseApp)
// export const firebaseAnalytics = getAnalytics(firebaseApp)

export const firebaseDb = firestoreDb
export const db = firestoreDb
export const app = firebaseApp
export const auth = firebaseAuth
export const functions = firebaseFunctions
// Export const storage = firebaseStorage
// export const performance = firebasePerformance
// export const analytics = firebaseAnalytics

// If on localhost, use all firebase services locally
if (location.hostname === 'localhost') {
	connectFirestoreEmulator(firestoreDb, 'localhost', 9090)
	connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
	connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001)
	// ConnectStorageEmulator(firebaseStorage, 'localhost', 9199)
}

// EnableIndexedDbPersistence(firestoreDb, {
//   synchronizeTabs: true,
// })
//   .then(() => {
//     console.log('Firestore Persistence DB: Activated for Offline Storage')
//   })
//   .catch((error) => {
//     console.log('Firebase Persistence DB: ERROR', error)
//     if (error.code === 'failed-precondition') {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (error.code === 'unimplemented') {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   })

// NOTE: Persistence Firestore and how to know when data is coming from the offline cache and is not guaranteed to be sync?
// when retrieving data from Firestore you do so with the `snapshot` object. If the `snapshot.fromCache` is `true`, that means that the data is from the cache and potentially out of sync with online data.
