<template>
  <ion-app>
    <ion-split-pane
      content-id="ion-router-outlet-content"
      when="(min-width: 950px)"
    >
      <Sidebar />
      <ion-router-outlet id="ion-router-outlet-content" />
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, IonSplitPane} from '@ionic/vue'
import {defineComponent} from 'vue'
import {mapGetters, mapState} from 'vuex'

import Sidebar from './components/base/Sidebar.vue'

export default defineComponent({
	name: 'App',
	components: {
		IonApp,
		IonRouterOutlet,
		IonSplitPane,
		Sidebar,
	},
	data() {
		return {
			readyToShow: false,
		}
	},
	computed: {
		...mapState(['route']),
	},
	watch: {
		route(state) {
			console.log('route changed', state)
		},
		userCurrent(state) {},
	},
	async mounted() {
		console.log('mounted app')
	},
	methods: {},
})
</script>
