import {createApp} from 'vue'

import {IonicVue} from '@ionic/vue'
import {sync} from 'vuex-router-sync'
import VueEasymde from 'vue3-easymde'
import dispatchActionForAllModules from '@/utils/dispatch-action-for-all-modules'
import BaseLayout from '@/components/base/BaseLayout.vue'
import store from '@/store'
import router from '@/router'

// App must be loaded after the other stuff cause it relies on stuff to be present!
import App from '@/App.vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/global.scss'

/* Vue EasyMDE Styles */
import 'easymde/dist/easymde.min.css'
import 'github-markdown-css'

sync(store, router, {moduleName: 'route'})

const app = createApp(App)
app.use(IonicVue)
app.use(store)
app.use(router)
app.use(VueEasymde)

app.component('BaseLayout', BaseLayout)

router.isReady().then(async () => {
	// Automatically run the `init` action for every module, if one exists.
	await store.restored
	await store.dispatch('auth/restore')

	dispatchActionForAllModules('init')
	console.log('store.state.auth.userCurrent:', store.state.auth.userCurrent)
	// Await the appSettings store to be restored before mounting the app.
	// await store.state.appSettings.restored
	app.mount('#app')
})
